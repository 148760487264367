import React from 'react';
import { Layout, Typography, Card, Button } from 'antd';
import { GiftFilled } from '@ant-design/icons';
const { Header, Content } = Layout;
const { Title, Text } = Typography;
const Donations = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
    <Header style={{ backgroundColor: '#001529', color: 'white' }}>
      
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
      <GiftFilled style={{ fontSize: '20px', color: 'white', marginRight: '26px' }} />
      <h2 style={{ color: 'white', margin: '0' }}>Donate Us</h2>
    </div>

      {/* <Title level={2} style={{ color: 'white', margin: '0' }}>Donate Us</Title> */}
    
    </Header>
    <Content style={{ padding: '20px', backgroundColor: '#f0f2f5' }}>
      <Card style={{minWidth:'10%', maxWidth: '50%', margin: 'auto', padding: '20px' }}>
    
        <Title level={3}> Support Our Cause</Title>
        <Text>
          Your contributions help us continue our work and make a difference in the community. Thank you for your support!
        </Text>
        
        <Title level={4} style={{ marginTop: '20px' }}>Bank Details</Title>
        <Text strong>Bank Name :  </Text> <Text>Wise Payments Limited</Text><br />
        <Text strong>Account Name :  </Text> <Text>Foundation for Rehabilitation & Education for Special Children Ltd</Text><br />
        <Text strong>Account Number :  </Text> <Text>61967513</Text><br />
        <Text strong>IBAN :  </Text> <Text>GB29 TRWI 2308 0161 9675 13</Text><br />
        
        {/* <Button type="primary" style={{ marginTop: '20px' }} href="https://www.yourpaymentlink.com" target="_blank">
          Donate Now
        </Button> */}
      </Card>
    </Content>
  </Layout>
  );
};

export default Donations;
